@font-face {
  font-family: Trenda-regular;
  src: url("./assets/font/LatinotypeTrendaRegular.woff");
}
@font-face {
  font-family: Trenda-bold;
  src: url("./assets/font/LatinotypeTrendaBold.woff");
}

body {
  background: #f3f4f5 !important;
  overflow-x: hidden !important;
  margin: 0;
  font-family: Trenda-regular, Trenda-bold, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
